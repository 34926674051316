import React from "react"
import alarm from "../images/alarm-white.png"
import tele from "../images/tele.png"

const Banner = () => {
  return (
    <div className="banner">
      <div className="container">
          <div className="inner">
                  <img src={alarm} alt="Notdienst Icon" />
        <div className="banner__notdienst">
          <h4>24 Stunden Notdienst</h4>
        </div>
        <div className="banner__text">
          <p>Wenn es taut, muss es schnell gehen!</p>
        </div>
        <div className="banner__contact">
        
            <a href="tel:+491726820240"> <span>Roland Vielhaber</span>
          <div className="phone">0172 – 68 20 24 0</div>
              <img src={tele} alt="Telefon Icon" /></a>
        </div>
                  <img src={alarm} alt="Notdienst Icon" />
      </div>
      </div>
    </div>
  )
}

export default Banner
