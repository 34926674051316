import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <section id="Impressum" className="impressum">
      <div className="container">
        <h2>Impressum</h2>
        <div class="section__content">
          <h3>Angaben gem&auml;ß § 5 TMG:</h3>
          <p>
            Roland Vielhaber
            <br />
            Partner der B&auml;cker - K&auml;lteservice
            <br />
            Schönenberg 1
            <br />
            33039 Nieheim
          </p>
          <h3>Kontakt:</h3>
          <p>
            Telefon: 052747684920
            <br />
            E-Mail: roland@vielhaber-online.de
          </p>
          <h3>Umsatzsteuer-ID:</h3>
          <p>
            Umsatzsteuer-Identifikationsnummer gem&auml;ß §27 a Umsatzsteuergesetz:
            <br />
            DE815489680
          </p>
          <h3>Aufsichtsbehörde:</h3>
          <p>
            Handwerkskammer Bielefeld <br /> <br />Berufsbezeichnung: K&auml;lteanlagenbauer
            <br /> Meister Zust&auml;ndige Kammer: Handwerkskammer Bielefeld <br />Verliehen
            durch: Deutschland/ NRW  <br />Es gelten folgende berufsrechtliche
          </p>
          <h3>Angaben zur Berufshaftpflichtversicherung:</h3>
          <p>
            Name und Sitz der Gesellschaft:  <br />ERGO Direkt <br />Geltungsraum der
            Versicherung: Europa
          </p>
          <h3>
          
            Quellenangaben für die verwendeten Bilder und Grafiken:</h3>
            <p>
                www.fotolia.de <br />
          vagabondo Quelle: http://www.e-recht24.de
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Impressum
