import React from "react"
import { Link } from "gatsby"
import Navigation from "./nav"
import logo from "../images/pdb_logo.svg"

const Footer = () => {
  return (
    <>
    <div className="footer__logo">
      <Link
        className="logo"
        to="/"
      >
        <img src={logo} alt={'Logo: Partner der B&auml;cker'} />
        </Link></div>
    <footer>
     
      <div className="container">
        <Navigation />
        <nav className="footer__navigation">
          <Link to="/impressum/">Impressum</Link>
            <span> | </span>© {new Date().getFullYear()}, Partner der B&auml;cker
          {/* <Link to="/impressum#datenschutz">Datenschutz</Link> */}

        </nav>
      </div>
    </footer>
    </>
  )
}

export default Footer
