import React, { Component } from 'react';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = { active: false };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(function (prevState) {
            return { active: !prevState.active }
        });
    }
    render() {
        return (
          <>
            <nav className={this.props.className + ' '+(this.state.active&&'active')} >
                    <a onClick={this.handleClick} href="/#Leistung">Leistung</a>
                    <a onClick={this.handleClick} href="/#Erfahrung">Erfahrung</a>
                    <a onClick={this.handleClick} href="/#Notdienst">Notdienst</a>
                    <a onClick={this.handleClick} href="/#Kontakt">Kontakt</a>
            </nav>
            {this.props.showtoggle && (
              <div
                onClick={this.handleClick}
                className={
                  this.state.active
                    ? "nav__toggle active"
                    : "nav__toggle"
                }
              >
                <span>Menü</span>
              </div>
            )}
          </>
        )
    }
}

export default Navigation;