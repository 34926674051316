import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/pdb_logo.svg";
import Navigation from "./nav";


const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="container">
      <div className="inner">
        <div className="header__logo">
          <Link className="logo" to="/">
            <img src={logo} alt={"Logo: " + siteTitle} />
          </Link>
          <h1>{siteTitle}</h1>
        </div>
        <div className="header__servicepartner">
          {/*Servicepartner für{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ice-cool.eu/"
          >
            <strong>IceCool</strong>
</a> */}
        </div>
        <div className="header__ansprechpartner">
          Erfahrener Spezialist für <strong>WP-Kühlanlagen</strong> bis Baujahr
          2013
        </div>
      </div>
    </div>
    <div className="container">
      <div className="inner">
        <Navigation className="nav__main" showtoggle={1} />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
